
/* hide the trash bin icon */
.anticon.anticon-delete {
    display: none;
    /* width: 0; */
    /* height: 0; */
    /* border: 1px solid green; */
}
button .ant-btn-icon span[aria-label="delete"] {
    display: none !important; 
    width: 0 !important;
    height: 0 !important;
    /* border: 1px solid crimson; */
}